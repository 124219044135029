// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlBackEnd : "https://api.fma.dev.techland.link/web",
  urlBackEndV2 : "https://api.fma.dev.techland.link/web/v2",
  urlBackEndV21 : "https://api.fma.dev.techland.link/web/v2.1",
  urlUploadBackEnd : "https://api.fma.dev.techland.link/web",
  baseHref : "",
  urlNewFmarket: "https://fma.dev.techland.link/",
  capcha : "6LcLr2cjAAAAAIcbGTBeYlhCbYshpMoSfe_Y_v0y",
  ga : "'UA-106272718-6'",
  asset : "https://s3-ap-southeast-1.amazonaws.com/fmarket-static/dev2",
  fcenter: "https://fcenter.fmarket.vn/",
  urlRobo : "https://api.robof.dev.techland.link/robof/",
  trueIdClientId: "c31a757a5bb285ab212a8fd9606b8eb3",
  trueIdClientSecret: "ka+G5ZrLjeKFewGHF8lLool9wKBgm94yCV3ZViBozMA=",
};
